$primary: #1a2238;
$secondary: #ee6c4d;
$dark: #101523;
$black: #151515;
$primary-10: #101523;
$primary-30: #303f69;
$primary-60: #7387bf;
$paper: #fafafa;

@import "bootstrap/scss/bootstrap";

#navigator {
  opacity: 90%;
  bottom: 3vh;
}

.form-control {
  background-color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  color: var(--primary-60);
}

.form-control:focus {
  background-color: var(--primary-30);
  color: var(--primary-60);
}

.fspan {
  background-color: var(--primary-10);
  color: var(--bs-light);
  border: 1px solid var(--bs-primary);
}

@include media-breakpoint-up(sm) {
  #profile-img {
    width: 10rem;
    height: 10rem;
    transform: translate(1rem, 4rem);
  }
  footer {
    height: 10vh;
    min-height: 11rem;
  }
  #contact {
    height: 90vh;
  }
  .card {
    width: 90%;
  }
  .card-img {
    width: auto;
  }
  #navigator {
    opacity: 100%;
    bottom: 8vh;
  }
}

@include media-breakpoint-up(md) {
  #profile-img {
    border-radius: 0;
    width: 12rem;
    height: 15rem;
    transform: translate(0, 0);
  }
  #cover-content {
    height: 100%;
    align-items: baseline;
  }
}

@include media-breakpoint-up(lg) {
  #profile-img {
    width: 15rem;
    height: 18rem;
  }
}
