.stack-cont {
  transform-style: preserve-3d;
  perspective: 400px;
}

.zoom-effect {
  animation: zoom-out-in 0.8s ease-out 0s 1 normal forwards;
}

.animate {
  animation: page-slide-in 0.6s ease-out 0s 1 normal forwards;
}

.stack-item-0 {
  transform: translate3d(0, 120x, -120px);
}

.stack-item-1 {
  transform: translate3d(0, 100px, -100px);
}
.stack-item-2 {
  transform: translate3d(0, 80px, -80px);
}

.stack-item-3 {
  transform: translate3d(0, 60px, -60px);
}

.stack-item-4 {
  transform: translate3d(0, 40px, -40px);
}

.stack-item-5 {
  transform: translate3d(0, 20px, -20px);
}

@keyframes page-slide-in {
  0% {
    transform: rotateX(90deg) scale(0.5) translate3d(0, -50px, 60vh);
  }
  100% {
    transform: rotateX(0) scale(1) translate3d(0, 0, 0px);
  }
}

@keyframes zoom-out-in {
  0% {
    transform: scale(1) rotateX(0);
  }
  30% {
    transform: scale(0.6) rotateX(60deg);
  }
  70% {
    transform: scale(0.7) rotateX(50deg);
  }
  100% {
    transform: scale(1) rotateX(0);
  }
}
