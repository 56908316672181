#terminal-window {
  max-height: 30rem;
  max-width: 60rem;
  overflow: hidden;
  border-radius: 5px;
  height: 75%;
  padding: 0;
  animation: term-in 8s ease-in-out 2s 1 normal forwards;
}

#wind-title {
  position: relative;
  border-radius: 5px 5px 0 0;
  display: flex;
  animation: term-title 1s ease-in 3.7s 1 normal forwards;
  width: 0;
}

#wind-title-text,
#hiddenDiv {
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}

#pop-up-dot {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50px;
  background-color: var(--bs-secondary);
  box-shadow: inset 0.5px 0.5px 1px var(--primary-10);
  position: relative;
  z-index: 2;
  animation: pop-up 1.7s linear 2.6s 1 reverse forwards;
}

#pop-down-dot {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50px;
  position: relative;
  background-color: #00d742;
  box-shadow: inset 0.5px 0.5px 1px var(--primary-10);
  animation: pop-up 1s linear 10.8s 1 normal forwards;
}

@keyframes term-in {
  0% {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transform: scale(0.3) translate(0, -12rem);
    background-color: var(--bs-secondary);
    overflow: hidden;
  }
  15% {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(0, 18rem) scale(0.6);
  }
  20% {
    transform: scale(0.9) translate(0, -6rem);
  }
  25% {
    width: 90%;
    height: 75%;
    border-radius: 7px;
    transform: scale(1.1) translate(0, -2rem);
    background-color: var(--bs-black);
    overflow: hidden;
  }
  30% {
    width: 90%;
    border-radius: 5px;
    transform: scale(1) translate(0, -2rem);
    overflow: visible;
  }
  60% {
    transform: scale(1) translate(0, -2rem);
    width: 90%;
    height: 75%;
  }
  80% {
    transform: scale(1.2) translate(14%, 0);
  }
  100% {
    transform: scale(3) translate(35%, 40%);

    background-color: var(--bs-black);
  }
}

@keyframes term-title {
  0% {
    width: 0;
    visibility: hidden;
    z-index: -1;
  }
  50% {
    width: 0;
    background-color: transparent;
  }
  70% {
    visibility: visible;
    z-index: -1;
  }
  100% {
    width: 100%;

    background-color: #b9c3df;
    z-index: 0;
  }
}

@keyframes pop-up {
  0% {
    transform: scale(1);
    z-index: 0;
  }
  30% {
    transform: scale(20);
    background-color: var(--bs-secondary);
    z-index: 2;
  }
  70% {
    transform: scale(500);
    background-color: var(--bs-secondary);
    z-index: 2;
  }
  100% {
    transform: scale(500);
    background-color: var(--bs-secondary);
    z-index: 2;
  }
}

@keyframes wind-zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
