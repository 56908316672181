:root {
  --primary-10: #101523;
  --primary-30: #303f69;
  --primary-60: #7387bf;
  --paper: #fafafa;
  --black: #151515;
  scroll-behavior: auto !important;
}

@import "./css/terminal.css";
@import "./css/stackStyle.css";
@import url("https://fonts.googleapis.com/css2?family=Carter+One&family=Lobster&display=swap");

body {
  width: 100vw;
  overflow-x: hidden;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}
#profile-img {
  background: url(./assets/images/img-cover.jpg) center top no-repeat;
  background-size: cover;
}

.logo-text {
  font-family: "Lobster", cursive;
  text-decoration: none;
  color: var(--bs-secondary);
}

a {
  text-decoration: none;
}

#profile-img {
  max-width: 400px;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  transform: translate(1rem, 2.1rem);
  position: relative;
}

#cover {
  background: url(./assets/BGs/bg01.svg) center no-repeat;
  background-size: cover;
}

#page-stack {
  background: url(./assets/BGs/CircuitPrimary.svg) center no-repeat;
  background-size: cover;
}

#cover-content {
  min-height: 18rem;
  overflow: hidden;
}

#cover-name {
  font-family: "Carter One", cursive;
}

#about {
  align-items: center;
  min-height: 27rem;
  background: url(./assets/BGs/bg04.svg) center no-repeat;
  background-size: cover;
}

.text-about {
  margin-bottom: 1.5rem;
  opacity: 0.9;
}

#projects {
  min-height: 41rem;
  background: url(./assets/BGs/bg03.svg) center no-repeat;
  background-size: cover;
}

.proj-cards {
  background: url(./assets/BGs/bgprojectcard.svg) center no-repeat;
  background-size: cover;
}

#contact {
  min-height: 38rem;
  background: url(./assets/BGs/bg01.svg) center no-repeat;
  background-size: cover;
}

#contact-card {
  background: url(./assets/BGs/bgcontact.svg) center no-repeat;
  background-size: cover;
}

footer {
  min-height: 15rem;
}

.nav-dot:hover {
  transform: scale(1.2);
}

.card {
  max-width: 650px;
  width: 80%;
}

.card-img {
  width: 100%;
}

.nav-shadow {
  box-shadow: 0 1px 2px #00000040;
}

.navg-dot:active {
  transform: scale(1.4);
}

#cover-dot {
  animation: into-home 1s ease-in-out 0s 1 normal forwards;
}

#footer {
  background: url(./assets/BGs/bg00.svg) center no-repeat;
  background-size: cover;
}

@keyframes into-home {
  0% {
    transform: scale(1000);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes item-in {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
